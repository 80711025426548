import React from 'react';
import './App.css';
import { Button, Nav, Icon, Field, Control } from 'reactbulma';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faArrowRight, faCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AvariceLogo from "./assets/avarice-logo.png";
import MesoverseLogo from "./assets/Mesoverse-Logo.png";
import IzingaLogo from "./assets/izinga-logo.png";
import Konjur3DLogo from "./assets/konjur3d-logo.png";

import FishMatcherImage from "./assets/fish-matcher.jpg";
import FishMatcherTech1Image from "./assets/fish-matcher-tech1.png";
import FishMatcherTech2Image from "./assets/fish-matcher-tech2.png";
import WrathImage from "./assets/wrath-screenshot.png";

library.add(faCheckSquare, faArrowRight, faCircle, faChevronRight)

class App extends React.Component {

  constructor(props){
    super(props)
    
    var target = Math.floor(Math.random() * 50);
    
    this.state = {
      map_img: null,
    
      width: 0, 
      height: 0,
      
      menu: 'introduction',
      
      menuOpen: false,
      
      show: true,
      withAnimation: true,
      
      
      lander: [0, 0],
      lander_angle: 0,
      lander_angle_vel: 0,
      
      lander_vel: [0, 0],
      lander_thrust_up: 0,
      lander_thrust_right: 0,
      lander_thrust_left: 0,
      lander_rotate_right: 0,
      lander_rotate_left: 0,
      
      t: 0,
      stars_phase: Array.from({length: 100}, () => Math.floor(Math.random() * 360.0)),
      stars_rad: Array.from({length: 100}, () => Math.floor(Math.random() * 1500)),
      
      landscape: Array.from({length: 50}, () => Math.floor(10 + Math.random() * 70)),
      landscape_colors: Array.from({length: 50}, () => Math.floor(Math.random() * 70)),
      
      target: target,
      
      width: 300,
      height: 300,
      win: "",
      landerFast: false
    }
    
    this.state.landscape[target + 1] = this.state.landscape[target];
    
    // Bindings
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setMenu = this.setMenu.bind(this);
    
    // Animations
    this.animationFrame = this.animationFrame.bind(this);
    
    // Setup animations
    var animationFrame = this.animationFrame;
    
    setTimeout(function() { 
      animationFrame();
      }, 30); 
  }
  
  
  checkLandscapeCollision(x1, y1, x2, y2, x3, y3, x4, y4) {
    if (x3 >= x1 && x3 <= x2) {
      if (y4 >= y1) {
        return true;
      }
    }
    
    if (x4 >= x1 && x4 <= x2) {
      if (y4 >= y1) {
        return true;
      }
    }
    
    return false;
  }
  
  animationFrame() {

    if (this.state.menu != "lander") {
      return;
    }
  
    // Lander physics
    var lander = this.state.lander;
    var lander_vel = this.state.lander_vel;
    var lander_angle = this.state.lander_angle;
    var lander_angle_vel = this.state.lander_angle_vel;
    
    var t = this.state.t;
    
    t = t + 1;
    
    lander[0] = 0.030 * lander_vel[0] + lander[0];
    lander[1] = 0.030 * lander_vel[1] + lander[1];
    
    lander_angle = 0.030 * lander_angle_vel + lander_angle;
    
    // Gravity
    lander_vel[0] = 0.030 * 10 + lander_vel[0];
     
    if (this.state.lander_thrust_up == 1) {
      lander_vel[0] = -0.07 * 60 * Math.sin((lander_angle + 90 ) / 180 * Math.PI) + lander_vel[0];
      lander_vel[1] = -0.07 * 60 * Math.cos((lander_angle + 90) / 180 * Math.PI) + lander_vel[1];
    }
    
    if (this.state.lander_thrust_right == 1) {
      lander_vel[1] = 0.030 * 10 + lander_vel[1];
    }
    
    if (this.state.lander_thrust_left == 1) {
      lander_vel[1] = -0.030 * 10 + lander_vel[1];
    } 
    
    if (this.state.lander_rotate_right == 1) {
      lander_angle_vel = 0.10 * 10 + lander_angle_vel;
    }
    
    if (this.state.lander_rotate_left == 1) {
      lander_angle_vel = -0.10 * 10 + lander_angle_vel;
    } 
    
    var win = "";
    var landerFast = false;
  
    if (Math.sqrt(lander_vel[0] * lander_vel[0] + lander_vel[1] * lander_vel[1]) < 20) {
    }
    else {
      landerFast = true;
    }

    // Lander collisions
    this.state.landscape.forEach( (__, i) => {
      const y1 = this.state.height - __ * this.state.height / 100.0;
      const x1 = this.state.width * i / 50.0;
      const y2 = y1 + __ * this.state.height / 100.0;
      const x2 = x1 + this.state.width / 50.0;
      
      const x3 = lander[1];
      const y3 = lander[0];
      const x4 = lander[1] + 30;
      const y4 = lander[0] + 30;
      
      if (this.checkLandscapeCollision(x1, y1, x2, y2, x3, y3, x4, y4)) {
      
        if (Math.sqrt(lander_vel[0] * lander_vel[0] + lander_vel[1] * lander_vel[1]) < 20) {
          if (lander_vel[0] >= 0) {
    	    // you landed
            lander_vel = [0, 0];
            lander_angle_vel = 0;
            lander_angle = 0;
            
            if (i == this.state.target || i == this.state.target + 1) {
              win = "YOU WIN!!!";
            } 
          }
        }
        else 
        {
          lander = [0, 0];
          lander_vel = [0, 0];
          lander_angle_vel = 0;
          lander_angle = 0;
        }
      }
      }); 
  
    this.setState({ landerFast, win: win, lander: lander, lander_vel: lander_vel, lander_angle: lander_angle, lander_angle_vel: lander_angle_vel, t: t });
  
    var animationFrame = this.animationFrame;
    
    setTimeout(function() { 
      animationFrame();
      }, 30);
  }
  
  setMenu(menu) {
    var prevMenu = this.state.menu;
   
    this.setState({menu: menu});
    
    // Update dimensions
    var updateWindowDimensions = this.updateWindowDimensions;
    var animationFrame = this.animationFrame;
    
    if (menu == "lander") {
      setTimeout(function() { 
        updateWindowDimensions(); }
        , 500);
      setTimeout(function() { 
        animationFrame();
        }, 30);
    }
  }
  
  setShow(show, base) {
    base.setState({show: show});
  }
  
  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
      });
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('load', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (document.getElementById('sizer') !== null) {
      const height = document.getElementById('sizer').offsetHeight;
      const width = document.getElementById('sizer').offsetWidth;
  
      this.setState({ 

        width: width, 
        height: height
        });
    }
  }
  
  render() {
   
    return (
    <section id="main" className="main">
      <div id="sizer" className="sizer">
      </div>
      
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a href="/"><img style={{marginTop: "13px"}} className="title-logo" src="relentless-no-text.png" /></a>

          <a role="button" className={`navbar-burger burger ${ this.state.menuOpen ? 'is-active' : '' }`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
            onKeyDown={() => this.toggleMenu() }
            onClick={() => this.toggleMenu() }>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${ this.state.menuOpen ? 'is-active' : '' }`}>
          <div className="navbar-start noselect">
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('introduction');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('introduction');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'introduction' ? "navbar-live" : ""}`}>
              INTRODUCTION
            </a>
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('products');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('products');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'products' ? "navbar-live" : ""}`}>
              PRODUCTS
            </a>
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('services');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('services');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'services' ? "navbar-live" : ""}`}>
              SERVICES
            </a>
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('ventures');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('ventures');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'ventures' ? "navbar-live" : ""}`}>
              VENTURES
            </a>

            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('privacy');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('privacy');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'privacy' ? "navbar-live" : ""}`}>
              PRIVACY
            </a>
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('contact');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('contact');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'contact' ? "navbar-live" : ""}`}>
              CONTACT
            </a>

            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('lander');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('lander');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'lander' ? "navbar-live" : ""} no-lander`}>
              MOON LANDER!
            </a>
            
            
            { /*
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('features');
                //this.setShow(true);
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('features');
              }
              }
              }
            
              className={`navbar-item ${this.state.menu == 'features' ? "navbar-live" : ""}`}>
              DOCUMENTATION
            </a>
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('contact');
                //this.setShow(true);
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('contact');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'contact' ? "navbar-live" : ""}`}>
              CONTACT
            </a> */ }
          </div>

          { /*
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a href="https://online.universe.org.za/" 
                  className="button sign-up">
                  <strong>SIGN UP</strong>
                </a>
                <a href="" className="button is-light">
                  <strong>LOG IN</strong>
                </a>
              </div>
            </div>
          </div>
          */ }
        </div>
      </nav>

  	  { this.state.menu == 'introduction' ? <div>
        <div className="intro-background">
  	    </div>

        

        <div className="intro-shield">
          <div style={{float: "right", fontSize: "0.4em", color: "#FFFFFF !important"}}>{ Date.now() }</div>

          <div style={{ position: "absolute", top: "170px", left: "200px", width: "3px", height: "2px", animation: "blink1 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "175px", left: "200px", width: "3px", height: "2px", background: "#FF0000"}}></div>
          <div style={{ position: "absolute", top: "220px", left: "200px", width: "3px", height: "2px", background: "#FF0000"}}></div>
          <div style={{ position: "absolute", top: "175px", left: "520px", width: "3px", height: "2px", background: "#FF0000"}}></div>
          <div style={{ position: "absolute", top: "220px", left: "520px", width: "3px", height: "2px", background: "#FF0000"}}></div>
          <div style={{ position: "absolute", top: "225px", left: "520px", width: "3px", height: "2px", animation: "blink2 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "480px", left: "900px", width: "3px", height: "2px", animation: "blink5 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "480px", left: "920px", width: "3px", height: "2px", animation: "blink3 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "480px", left: "930px", width: "3px", height: "2px", animation: "blink4 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "480px", left: "950px", width: "3px", height: "2px", animation: "blink1 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "540px", left: "900px", width: "3px", height: "2px", animation: "blink1 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "540px", left: "920px", width: "3px", height: "2px", animation: "blink5 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "540px", left: "930px", width: "3px", height: "2px", animation: "blink6 1s infinite"}}></div>
          <div style={{ position: "absolute", top: "540px", left: "950px", width: "3px", height: "2px", animation: "blink3 1s infinite"}}></div>
        </div>

        <div className="privacy-div">
            This site does not use cookies.
          </div>

        <div className="content" style={{ marginTop: "150px", height: "60vh"}}>
          
          <h2>
  	        <font style={{fontWeight: "bold", fontSize: "1.55em"}}>RELENTLESS&nbsp;&nbsp;&nbsp;</font>
  	      </h2>
          <h3>
            [rɪˈlɛntləs]
          </h3>
          <h3>
            PROPER NOUN - a high-tech; business management solution provider, investor and lunatic friendly company
          </h3>
        </div>
  	  
  	    <div className="content" style={{ width: "calc(100vw)", minHeight: "100vh", marginLeft: "0px", paddingTop: "50px", paddingLeft: "100px", paddingRight: "100px", marginRight: "0px", marginTop: "17vh", backgroundColor: "#191919FA", borderRadius: "0px" }}>
        <h2>WHO ARE WE</h2>
  	      <p>
  	        Relentless is a information technology business that specializes in business management  solutions integrated with financial and accountings systems.
  	        We focus on two main areas:  Gig economy and ERP.  Our ERP (Enterprise Resource Planning) engine is used in many types of businesses for 
  	        procurement, supplier/customer vetting, document processing, accounting and many other things we didn't initially consider.  
  	      </p>
          <p>
            If you're here for ERP, please follow the link to Avarice ERP our flagship system.
          </p>
  	      <div style={{ marginBottom: "70px", textAlign: "center", width: "100%" }}><a href="https://www.avarice.co.za" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;Avarice Homepage</strong></a></div>
          <p>
  	        In 3D graphics we sell an iPad based 3D modelling tool called Konjur3D.  It provides facilities to do hard surface modelling, clay sculpting and texture editting.  The tool is 
            an all in one product and can export to the major standards of STL, OBJ, GLTF and USDZ (for AR applications).  It is available on the Apple Appstore for once off purchase at $15.  
            No subscription frees are required for the base package which includes most modelling functions.  Animation and generative tools are in development for the solution.   
  	      </p>
  	      <div style={{ marginBottom: "70px", textAlign: "center", width: "100%" }}><a href="https://www.konjur3d.com" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;Konjur3D Homepage</strong></a></div>

  	      <p>
  	        In gig economy we offer Mesoverse, that automates most of the market-making and payment functions necessary in business.  A user can canvas his
            entire business flow and the system will realize this functionality across Android and iOS apps.  The system can be configured for most 
            types of decentralized or centralized business types including retail management, business process outsourcing and car rental.  Trust us, we've done
            the rounds!
  	      </p>
  	      <div style={{ marginBottom: "70px", textAlign: "center", width: "100%" }}><a href="https://www.mesoverse.net" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;Mesoverse Homepage</strong></a></div>
  	      <h4>
            CONSULTING SERVICES
          </h4>
  	      <p style={{marginBottom: "300px"}}>
  	        In addition to our products, we also provide finanical, software and engineering services.  Our team
  	        has enormous experience in areas such as fraud and payment.  We have also developed various solutions
  	        in more advanced engineering fields such as biometrics, weapons development and sensor design.
  	      </p>
  	    </div>
  	  </div>
  	  :
  	  null
  	  }
  	  
  	  { this.state.menu == 'products' ? <div>
      
        <div className="product-background">  
        </div>
    
        <div className="intro-shield">
        </div>
    
        <div className="content" style={{ marginTop: "150px"}}>
          <h2>
            PRODUCTS&nbsp;&nbsp;&nbsp;
          </h2>
          <h3>
            Our Platforms
          </h3>
        </div>
  	  
  	    <div className="content" style={{ width: "calc(100vw)", minHeight: "100vh", marginLeft: "0px", paddingTop: "50px", paddingLeft: "100px", paddingRight: "100px", marginRight: "0px", marginTop: "17vh", backgroundColor: "#000000", borderRadius: "0px" }}>
        <div>
  	        <h4><a href="https://www.avarice.co.za" target="_blank"><img className="product-logo-1" src={AvariceLogo}/></a></h4>
            <h5>FUTURE ERP</h5>
  	        <p>
  	          Avarice is our premier ERP (Enterprise Resource Planning) solution designed with completeness and low/no code configuration in mind.  It provides
              accounting, procurement, customer relationship management (CRM) and human resources (HR) modules which are tuned to specific customer needs.  The system 
              is also used to support financial functions such as payment and debit orders.
            </p>  
            <h5>CONFIGURABLE</h5>
            <p>
              The architecture is highly 
  	          configurable through our Ropes graphical languages (a low-code environment).  This enables a high level of automation.  With build-in UI path 
              and document processing tools many workflows can be left to the machine to process itself.  It has many standard libraries for interfacing with
              other major systems should such interaction be necessary.  
            </p>
            <p>
              It consists of three main components:  Avarice Accounting, Avarice Forms and Avarice Procure.
  	        </p>
            <h5>ACCOUNTING</h5>
  	        <p>
  	          The accounting engine is a performance double-entry accounting solution which captures a complete financial
  	          statement from owner's equity to accounts payable.  This functions as the core of the system and enables a 
  	          business to capture its processes and data against the 'numbers.'  The accounting system will pay necessary 
  	          transactions on command and has a complete treasury cash management engine.
  	        </p>
            <h5>FLEXIBLE FORMS AND WORKFLOWS</h5>
            <p>
              Avarice Forms enables any workflow to be captured as a quick and easy form alongside its routing.  Approvals and signoff gatekeep
              forms from maturing to transactions.  The forms themselves can contain many types of questions, document attachments and are stored robustly to
              ensure all changes are recorded.  It serves human resources, legal and compliance functions easily.  A project/case management tool 
              ensures forms are executed by necessary stakeholders and progress can be dashboarded.  
              The system is dynamic enough to be used as a learning management system and tutorial provider for some of our clients.
            </p>
            <h5>ADVANCED PROCUREMENT</h5>
  	        <p>
  	          Avarice Procure is a complete procurement solution that can operate with or without accounting.  It handles everything from
  	          supplier vetting to payment.  The system is deployed in government and in large corporate operations where it serves to reduce
  	          paperwork and manual labor.  With a design philosophy centered on fraud prevention, most risks in supply chain are catered for
              in the design.
  	        </p>
  	        <div style={{ marginBottom: "100px", marginTop: "100px", textAlign: "center", width: "100%" }}><a href="https://www.avarice.co.za" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;Avarice ERP Homepage</strong></a></div>
  	      </div>
          <div class="product-divider"></div>

          

  	      <div>
            <h4><a href="https://www.konjur3d.com" target="_blank"><img className="product-logo" src={Konjur3DLogo} /></a></h4>
  	        <h4>
  	          KONJUR3D
  	        </h4>
  	        <p>
  	         Konjur3D is an iPad based 3D modelling tool that is simple to use and powerful.  It is best used with an Apple Pencil.  Clay,
             hard surface and 'from the block' modelling.  The editor has a built in texture editor and texture mapping tools.  It can export to 
             OBJ, GLTF and USDZ (for AR tools).
  	        </p>
            <div style={{ marginBottom: "100px", marginTop: "100px", textAlign: "center", width: "100%" }}><a href="https://www.konjur3d.com" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;Konjur3D Homepage</strong></a></div>
  	      </div>
          <div class="product-divider"></div>
  	      <div>  
  	        <h4>
  	          WRATH
  	        </h4>
  	        <p style={{ marginBottom: "100px" }}>
  	          We are currently developing a 3D graphics/large-scale network engine for VR and AR applications, particularly in business.  (Yes, another one, we know there are many out there.)  
              The system is completely platform agnostic and can be used in game development.  Speak to us for more information.
  	        </p>
  	      </div>

          <div class="product-divider"></div>

          <div>
  	        <h4><a href="https://www.mesoverse.net" target="_blank"><img className="product-logo" src={MesoverseLogo} /></a></h4>
            <h4>MESOVERSE</h4>
  	        
            <h5>IT BEGAN IN INTERVIEW SURVEYING</h5>
  	        <p>
  	          Mesoverse started life as a surveying tool used by interviewers to capture question and answer data.  These
  	          interviewees came from all walks of life.  The first survey executed by the system was a 80,000 point, 200 question 
  	          economic assessment of township enterprises in the Gauteng Province of South Africa.  This was executed in 2016.
  	          The system was then used to gather the 36,000 point Gauteng Central Region Observatory Quality-of-Life survey in 2018.
  	        </p>  
            <h5>SERVES TRADITIONAL BOOTS-ON-THE-GROUND BUSINESS MANAGEMENT</h5>  
  	        <p>
  	          After several rewrites and evolutions the system has become a comprehensive form and flow based
  	          business management tool, complete with productivity elements such as calendars and chat.  It is actively used by
  	          many businesses in South Africa under various white labels in industries that range from merchandising to 
  	          municipal permits.
  	        </p>
            <h5>ENABLES GIG ECONOMY</h5>
            <p>
              Beyond business management, the system facilitates gig economy ventures, automating transactions and interactions with customers.
              If you canvas a business flow the system will realize it as a dashboard and the necessary apps for the Google Play Store and Apple App Store platforms.
              We are trialing this form of the system in several ventures as a generalized tool for decentralized business.  
            </p>
  	        <div style={{ marginBottom: "100px", marginTop: "100px", textAlign: "center", width: "100%" }}><a href="https://www.mesoverse.net" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;Mesoverse Homepage</strong></a></div>
  	      </div>
  	    </div>          

  	  </div>
  	  :
  	  null
  	  }
  	  
  	  { this.state.menu == 'services' ? <div className="">
  	    <div className="services-background">
  	    </div>

        <div className="intro-shield">
        </div>
    
        <div className="content" style={{ marginTop: "150px"}}>
          <h2>
            CONSULTING
          </h2>
          <h3>
            We consult in R&D, audit, finance, forensics, IT and difficult problems.
          </h3>
        </div>
  	  
  	    <div className="content" style={{ width: "calc(100vw)", minHeight: "50vh", marginLeft: "0px", paddingTop: "50px", paddingLeft: "100px", paddingRight: "100px", marginRight: "0px", marginTop: "150px", backgroundColor: "#191919", borderRadius: "0px" }}>
          <h2>CASE STUDIES</h2>
  	      <p style={{ marginBottom: "20px"}}>
            <strong style={{ color: "#FFFFFF"}}>Case Study I:  Fish Matching for Oceanic Applications</strong>
          </p>
          <p style={{ marginBottom: "20px"}}>
            In 2021, we developed a fish matching system for an Australian client as an R&D project.  The system could match a single fish from a single image over a database of millions of fish.
          </p>
          <div>
            <img className="product-image" src={FishMatcherTech2Image} />
            <img className="product-image" src={FishMatcherTech1Image} />
            <img className="product-image" src={FishMatcherImage} />
          </div>
          <p style={{ marginBottom: "20px"}}>
            The system was developed using deepnets (in Tensorflow) to identify salient features in the fish and to match fish features like a fingerprint.  
            We achieved a performance of matching a single fish to the 1000 or more sample fish database of 2s.
          </p>
          <p style={{marginBottom: "100px"}}>
            In the development of the system, we developed an AI infrastructure for managing new trained models and
            native performance code for various complex algorithms.  Relentless has a 20 year track record in advanced machine learning and AI applications.
            This examples highlights our commerical efficiency in creating something realistically deployable.
          </p>
          <p style={{ marginBottom: "20px"}}>
            <strong style={{ color: "#FFFFFF"}}>Case Study II:  Large Scale Financial Transaction Reconciliation</strong>
          </p>
          <p style={{ marginBottom: "20px"}}>
            In 2022, we developed infrastructure on top of Google BigQuery to analyze many terabytes of bank transactions against a regulator's external database.
            Bank transactions were extracted from a legacy banking system typical of big banks and had to be compared based on various identifiers including timing, source and client.
            The project took 3 weeks to execute and a complete analysis of discrepancies was calculated.
          </p>
          <p style={{ marginBottom: "100px"}}>
            At Relentless, we have developed advanced tools for analyzing complex data that needs to be de-duplicated or matched in forensic or audit function. 
            These tools can handle petabyte scale problems and will work in real-time for per transaction analyses.  Email us for more information.
          </p>
          <p style={{ marginBottom: "20px"}}>
            <strong style={{ color: "#FFFFFF"}}>Case Study III:  Industrial VR and Computer Graphics</strong>
          </p>
          <p style={{ marginBottom: "20px"}}>
            We are currently developing a platform called Wrath written in Rust and built on the Vulkan graphics framework that can display high-end
            raytraced (DLSS) graphics.  A demand has been noted for highly realistic visualization of industrial operations.  We built the platform with
            reliability in mind (Rust enforces very strict code) and a server based supply of data.
          </p>
          <div>
            <img className="product-image-larger" src={WrathImage} />
          </div>
          <p style={{ marginBottom: "100px"}}>
            These advanced visualization tools, along with Konjur3D (our editting software), can enable rapid population of a VR training environments or
            control center visuals to optimize mine or industrial operations.  We are partnering with various industrial automation groups to embed our custom 
            engine in their toolchain.
          </p>
          <h2>OUR SERVICES</h2>
          <p style={{ marginBottom: "20px"}}>
  	        <strong style={{ color: "#FFFFFF"}}>Audit</strong> - The auditing industry is supported by analytics, technology specialists and compliance experts.  We provide many of these services with 
            particular strength in banking.  It is impossible to separate accurate comprehension of an audit client's technology from their financial statement.  We assist in bridging that understanding.  We
            also automate the processing of general ledgers and the search of accounting irregularities or anomalies.
  	      </p>
          <p style={{ marginBottom: "50px"}}>
  	        Avarice, initially built as an ERP, is often used to process client ERP data to assist in the internal and external audit processes.  Avarice assists in the following elements of audit:
            <ul>
              <li>Audit process management</li>
              <li>Accounts receivable (Trade receivables) aging and impairment analysis</li>
              <li>Accounts payable (Trade payables) and procurement risk analysis</li>
              <li>General ledger analysis and auto-allocation</li>
              <li>Anomalie detection (numeric, pattern based or via trained deepnet)</li>
              <li>Contract to financial evidence verification</li>
              <li>Governance checklist management</li>
              <li>Financial statement fair presentation analysis</li>
            </ul>
  	      </p>
  	      <p style={{ marginBottom: "50px"}}>
  	        <strong style={{ color: "#FFFFFF"}}>Banking Policy and Systems</strong> - Relentless has spent a decade working in the banking environment.  We've assisted in the development of policy 
            documentation, the design of banking infrastructure and software, and the development of fraud detection solutions.  We have a large network of professionals and long term relationships with core
            industry institutions in government, investigation and certification.
  	      </p>
  	      <p style={{ marginBottom: "50px"}}>
  	        <strong style={{ color: "#FFFFFF"}}>Engineering</strong> - Relentless offers engineering services in several fields including financial engineering, optics and camera design
  	        and electronics.  We also have extensive experience in sensor design for applications like spectrometry or security
  	        systems.  We also consult in the military domain, but predominantly in sensor systems and drones.
  	      </p>
  	      <p style={{ marginBottom: "50px"}}>
  	         <strong style={{ color: "#FFFFFF"}}>Fraud investigation</strong> - Relentless prides itself in the widespread application of its forensics and fraud detection systems.  We have consulted and deployed systems in the public and private sector and 
             pride ourselves in helping stem a growing white collar crime problem in Southern Africa.  Evidence collection, processing and readying for court are functions we execute on a monthly basis.  Our automation technologies
             increase our efficiency to a great extent.
  	      </p>
          <p style={{ marginBottom: "50px"}}>
  	         <strong style={{ color: "#FFFFFF"}}>IT</strong> - Have a hard IT problem you need solved?  Get in contact.  You'll be surprised what we've confronted in the last decade.
             Cloud systems and machine learning are our speciality.
  	      </p>
          <div style={{ width: "100%", textAlign: "center"}}>
            <img style={{ width: "400px" }} className="service-cycle" src="service-cycle.png" />
          </div>
        </div>
  	  </div>
  	  :
  	  null
  	  }
  	  
  	  { this.state.menu == 'ventures' ? <div className="">
      <div className="ventures-background">  
        </div>
    
        <div className="intro-shield">
        </div>
    
        <div className="content" style={{ marginTop: "150px"}}>
          <h2>
            VENTURES&nbsp;&nbsp;&nbsp;
          </h2>
          <h3>
            Our Current Investments
          </h3>
        </div>

  	    <div className="content"  style={{ width: "calc(100vw)", minHeight: "50vh", marginLeft: "0px", paddingTop: "50px", paddingLeft: "100px", paddingRight: "100px", marginRight: "0px", marginTop: "150px", backgroundColor: "#191919", borderRadius: "0px" }}>
  	      { /*<div className="logo-bit" style={{marginBottom: "50px"}}>
  	        <a href="https://www.ifuelu.co.za/" target="_blank"><img className="logo-image" src="ifuelu-logo.png" /></a>
  	        <p className="logo-blerb">
  	          <strong style={{ color: "#FFFFFF"}}>IFuelU</strong> - IFuelU is a fuel reward management company which serves and pays
  	          rewards to customers on behalf of fuel providers, oil companies and fleet management groups.
  	        </p>
  	        <a href="https://www.ifuelu.co.za/" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;IFuelU</strong></a>
          </div> */}
          <div className="logo-bit" style={{marginBottom: "150px"}}>
  	        <img className="logo-image-nb" src="darktree-logo.png" />
  	        <p className="logo-blerb">
  	          <strong style={{ color: "#FFFFFF"}}>Dark Tree Studio</strong> - Dark Tree is a video game and AR/VR experience engineering company.  The organization uses a custom built toolset
              for modelling and a custom built high-end raytracing graphics engine.  More to come.
  	        </p>
  	        <strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;DARK TREE (COMING SOON)</strong>
  	      </div>
  	      { /* <div className="logo-bit" style={{marginBottom: "50px"}}>
  	        <a href="https://www.ofin.co.za/" target="_blank"><img className="logo-image-nb" src="ofin-logo.png" /></a>
  	        <p className="logo-blerb">
  	          <strong style={{ color: "#FFFFFF"}}>OFIN</strong> - OFIN is a owner-driver market place and fleet management system 
  	          designed to optimize the finances and business health of truck owners in various fleet ecosystems.
  	        </p>
  	        <a href="https://www.ofin.co.za/" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;OFIN</strong></a>
            </div> */ }
          { /* <div className="logo-bit" style={{marginBottom: "50px"}}>
  	        <a href="https://www.izingafoundation.org/" target="_blank"><img className="logo-image-nb" src={IzingaLogo} /></a>
  	        <p className="logo-blerb">
  	          <strong style={{ color: "#FFFFFF"}}>Izinga Foundation</strong> - The Izinga Foundation is mandated to support and grow
              SMMEs in Southern Africa.  It achieves this through partnerships with Gauteng Provincial Government and various of its departments
              and some private sector participants like NBI and Bluefields.
  	        </p>
  	        <a href="https://www.izingafoundation.org/" target="_blank"><strong style={{ color: "#FFFFFF"}}><FontAwesomeIcon icon="chevron-right" />&nbsp;&nbsp;IZINGA</strong></a>
  	      </div> */ }
          <div className="logo-bit" style={{marginBottom: "50px", width: "100%", textAlign: "center"}}>
            <FontAwesomeIcon icon="circle" />&nbsp;&nbsp;<FontAwesomeIcon icon="circle" />&nbsp;&nbsp;<FontAwesomeIcon icon="circle" />
            <p style={{marginTop: "50px"}}>
              More to follow
            </p>
  	      </div>
  	    </div>
  	  </div>
  	  :
  	  null
  	  }

{ this.state.menu == 'privacy' ? <div className="">
      <div className="ventures-background">  
        </div>
    
        <div className="intro-shield">
        </div>
    
        <div className="content" style={{ marginTop: "150px"}}>
          <h2>
            PRIVACY POLICY&nbsp;&nbsp;&nbsp;
          </h2>
        </div>

  	    <div className="content"  style={{ width: "calc(100vw)", minHeight: "50vh", marginLeft: "0px", paddingTop: "50px", paddingLeft: "100px", paddingRight: "100px", marginRight: "0px", marginTop: "150px", backgroundColor: "#191919", borderRadius: "0px" }}>

        <h3>Relentless Partners (Pty) Ltd Privacy Policy</h3>
        <p>This privacy policy provides information regarding the processing of your personal data when using 
          the Mesoverse or Avarice platforms or derivatives such as Promt and ResearchGO.</p>
        <p>
          This privacy notice explains what personal data is processed, for which purposes, who we share the 
          personal data with, who is responsible for your data, data security and the how long we retain your 
          personal data.
        </p>
        <h3>What personal data do we process?</h3>
        <p>We collect information, including personal data about you, as a App user of our platforms. This 
          information may be either:
        </p>
        <p>
          § Information that you provide to us – when registering for use of the software, you will 
          be required to provide your name, mobile phone number, and location. You may also be required to provide your email address and bank 
          account details (in the event of cash payments). In certain instances electronic device 
          information, such as IP addresses and location information are recorded. We may ask you to 
          provide further personal data necessary for the performance of such services and/or 
          authentication.
        </p>
        <p>
          § Information that we obtain through your use of the platforms - information 
          about how and where you use the systems will be collected, in addition to the data you provide for the systems to work in a Gig economy manner. 
        </p>
        <h3>Why do we process your personal data?</h3>
        <p>The personal data covered by this privacy notice is only processed:</p>
        <p>§ Where it is necessary to conclude a transaction with you; or</p>
        <p>§ With your explicit consent; or</p>
        <p>§ Where it is necessary for Relentless Partners to comply with a legal obligation.</p>
        <p>In concluding a transaction with you, personal data is used for the purposes of;</p>
        <p>§ Determining the value of your gig remuneration or validating the work done;</p>
        <p>§ Dispersing your payment to you;</p>
        <p>§ Account management including account verification (such as password protection to ensure
          that only you can access your account and information); and</p>
        <p>§ Detecting or preventing fraud.</p>
        <p>Where the processing is based on consent, you have the right to withdraw your consent at any time. 
          This will not affect the validity of the processing prior to the withdrawal of consent. Where you 
          choose not to provide us with information set out above for the purposes of using a Relentless Partners 
          Appit will affect your ability to fully interact 
          with the App and/or to participate in the platforms.
          Who do we share your data with?</p>
        <p>Your personal data is exclusively processed for the purposes referred to above and will only be 
          shared on a strict need to know basis with:</p>
          <p>§ Authorized service providers involved in payments (such major banks and airtime reward
          providers);</p>
        <p>§ Authorised agents, licensees, service providers and/or subcontractors of Relentless Partners; and/or</p>
        <p>§ A competent public authority, government, regulatory or fiscal agency where it is necessary to
          comply with a legal or regulatory obligation to which Relentless Partners is subject to or as permitted by
          applicable local law.</p>
          <h3>Who is responsible for any data collected?</h3>
        <p>Data security is a priority at Relentless Partners. Relentless Partners’s Chief Technology Officer is responsible for the 
          processing of your personal data. This includes data protection strategies, governance of data 
          processing activities, creating a culture of data protection awareness, creating multiple layers of 
          encryption and security and conducting regular data security reviews.</p>
        <h3>How is your data secured?</h3>
        <p>Relentless Partners takes organizational, contractual and legal measures to ensure that your personal data is 
          exclusively processed for the purposes mentioned above and that adequate levels of protection 
          have been implemented to safeguard your personal data.</p>
        <p>We have implemented technology with the objective of protecting your privacy from unauthorised 
          access and improper use. In particular, we may use encryption for some of our services, we apply 
          authentication and verification process for access to Relentless Partners services and we regularly test, assess 
          and evaluate the effectiveness of our security measures.</p>
        <p>Appropriate back-up and disaster recovery servers and solutions are in place.</p>
        <h3>How long do we retain your data?</h3>
        <p>Data is retained as follows:</p>
        <p>§ Personal data will be deleted upon you requesting to discontinue your participation in a platform OR within 3 years for all subscribed services after our last 
          interaction with you;</p>
        <p>§ Transactional data will be archived after 3 months;</p>
        <p>§ In relation to financial transactions, your personal data will be held for 10 years from the 
          transaction.</p>
          <p>
            In all cases information may be held for a) a longer period of time where there is a legal or 
            regulatory reason to do so (in which case it will be deleted once no longer required for the legal or 
            regulatory purpose) or b) a shorter period where the individual objects to the processing of their 
            personal data and there is no longer a legitimate purpose to retain it.
          </p>
          <h3>Changes to this Privacy Policy</h3>
          <p>
            This Privacy Policy may be changed over time. You are advised to regularly review the Privacy Policy 
            (https://www.relentless.co.za) for possible changes but if the changes are significant, we will provide 
            you with an appropriate notice, including e-mail notification if necessary. This Privacy Policy was last 
            updated in September 2019.
          </p>
  	    </div>
  	  </div>
  	  :
  	  null
  	  }
  	  
  	  { this.state.menu == 'contact' ? <div>
  	  
  	    <div className="content-contact">
  	      <h2>
  	        CONTACT
  	      </h2>
  	      <p style={{fontSize: "0.8em", marginTop: "10px"}}>
  	        Please contact us at <a href="mailto://jon@relentless.co.za"><strong style={{ color: "#FFFFFF"}}>jon@relentless.co.za</strong></a>
  	      </p>
  	      
  	    </div>
  	  </div>
  	  :
  	  null
  	  }
  	  
  	  { this.state.menu == 'lander' ? <div>
  	    
  	      <div 
  	        id="landermain"
  	        className="lander-background"
  	        tabIndex="0"
  	      
  	        onKeyDown={(event) => {
  	          if (event.key == 'ArrowUp') {
  	            this.setState( { lander_thrust_up: 1 } );
  	          }
  	          
  	          if (event.key == 'ArrowRight') {
  	            this.setState( { lander_rotate_right: 1 } );
  	          }
  	          
  	          if (event.key == 'ArrowLeft') {
  	            this.setState( { lander_rotate_left: 1 } );
  	          }
  	          }}
  	        onKeyUp={(event) => {
  	         
  	          if (event.key == 'ArrowUp') {
  	            this.setState( { lander_thrust_up: 0 } );
  	          }
  	          
  	          if (event.key == 'ArrowRight') {
  	            this.setState( { lander_rotate_right: 0 } );
  	          }
  	          
  	          if (event.key == 'ArrowLeft') {
  	            this.setState( { lander_rotate_left: 0 } );
  	          }
  	          }}>    
  	      
  	        { this.state.stars_phase.map( (_, i) => 
  	          <div 
  	            style={{ 
  	              top: Math.min(Math.sin(_ + this.state.t * 0.001) * this.state.stars_rad[i], this.state.height - 4), 
  	              left: Math.cos(_ + this.state.t * 0.001) * this.state.stars_rad[i] }}
  	            className="star">
  	          </div>
  	          )}
  	      
  	        <div
              style={{ fontSize: "0.6em", color: "#000000", top: this.state.lander[0] + "px", left: this.state.lander[1] + "px", transform: "rotate(" + this.state.lander_angle + "deg)" }}
  	          className={ `lander ${ this.state.lander_thrust_up ? "up-thrust" : "" } ${ this.state.lander_thrust_left ? "left-thrust" : "" } ${ this.state.lander_thrust_right ? "right-thrust" : "" }`}
              >
              ML
              <div className={'lander-left-leg'}></div>
              <div className={'lander-right-leg'}></div>
              <div className={'lander-head'}></div>
              { this.state.lander_thrust_up && <div className={'lander-flame'}></div> }
            </div>
  	        
  	        { this.state.landscape.map( (_, i) => 
  	          <div 
  	            key={ "land_" + i }
  	            style={{ 
  	              top: this.state.height - _ * this.state.height / 100, 
  	              left: this.state.width * i / 50.0, 
  	              width: this.state.width / 50.0, 
  	              height: (_ * this.state.height / 100),
  	              backgroundColor: i == this.state.target || i == this.state.target + 1 ? 
  	                "#4444DD"
  	                : "#" + (255 - this.state.landscape_colors[i]).toString(16) + (255 - this.state.landscape_colors[i]).toString(16) + (255 - this.state.landscape_colors[i]).toString(16)
  	             }}
  	            className="moon">
  	          </div>
  	          )}
  	          
  	        <div className="win-state">
  	          <p style={{ fontSize: "1.5em", color: "#00FF00" }}>{ this.state.win }</p>
  	        </div>
  	          
  	        <div className="instructions">
  	          <p style={{ fontSize: "1.2em"}} >CLICK TO PLAY!</p>
  	          <p> </p>
  	          <p>LEFT AND RIGHT ARROW KEYS ROTATE THE VESSEL</p>
  	          <p>UP ARROW TO THRUST</p>
  	          <p>LAND ON THE BLUE DESTINATION PILLAR <font style={{color: "red"}}>SLOWLY (VELOCITY LESS THAN 20)</font></p>
              <p>Y VELOCITY: { Math.round(Math.sqrt(this.state.lander_vel[0] * this.state.lander_vel[0] + this.state.lander_vel[1] * this.state.lander_vel[1])) } { this.state.landerFast && <font style={{color: "red"}}>TOO FAST TO LAND</font>}</p>
  	        </div>
  	      
  	        
  	      </div>
  	      
  	  </div>
  	  :
  	  null
  	  }
  	 
  	  
      <div className="legal">
  	    <div className="legal-image">
  	      <img width="100px" src="relentless-logo.png">
  	      </img>
  	    </div>
  	    <p>&copy; Copyright 2014 - 2024 Relentless Partners (Pty) Ltd All Rights Reserved.</p>
  	  </div>
    </section>
    );
  };
}

export default App;
